<template>
  <div id="user-deletion">
    <h1>Request User Deletion</h1>
    <div class="user-deletion-form">
      <div>
        <div class="submit-success">
          <p v-if="message">
            {{ message }}
          </p>
        </div>
        <div class="submit-errors">
          <p v-if="error">
            {{ error.message }}
          </p>
        </div>
        <validation-observer ref="form" v-slot="{ invalid }">
          <form @submit.prevent="onSubmit">
            <validation-provider rules="required|uuid" name="User ID" v-slot="{ errors }">
              <div class="form-row" :class="{ 'error': errors.length > 0 }">
                <label for="txtUserID">User ID <fa icon="asterisk" /></label>
                <input id="txtUserID" type="text" :disabled="isBusy" placeholder="Enter User ID" v-model="formData.userID" required>
                <p v-if="errors.length > 0" class="error">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
            <validation-provider rules="required|email" name="Email Address" v-slot="{ errors }">
              <div class="form-row" :class="{ 'error': errors.length > 0 }">
                <label for="txtEmailAddress">Email Address<fa icon="asterisk" /></label>
                <input id="txtEmailAddress" type="text" :disabled="isBusy" placeholder="Email Address" v-model="formData.emailAddress" required>
                <p v-if="errors.length > 0" class="error">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
            <validation-provider rules="min:3|max:250" name="Reason" v-slot="{ errors }">
              <div class="form-row" :class="{ 'error': errors.length > 0 }">
                <label for="txtReason">Reason</label>
                <textarea rows="5" id="txtReason" type="text" :disabled="isBusy" placeholder="Enter Reason" v-model="formData.reason"></textarea>
                <p v-if="errors.length > 0" class="error">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
            <p v-if="isBusy" class="busy">
              Please wait..
            </p>
            <div class="form-row">
              <button :disabled="isBusy || invalid" class="es-btn teal">Submit</button>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      formData: 'user/payload',
      message: 'user/message',
      isBusy: 'user/isBusy',
      error: 'user/error'
    })
  },
  methods: {
    ...mapActions({
      deleteUser: 'user/deleteUser'
    }),
    async onSubmit() {
      const success = await this.deleteUser();
      if (success) {
        this.$refs.form.reset()
      }
    }
  },
  beforeMount() {
    const { uid, email, reason } = this.$route.query
    this.formData.userID = uid
    this.formData.emailAddress = email
    this.formData.reason = reason ?? null
  }
}
</script>
<style lang="scss" scoped>
#user-deletion {
  margin-top: 3em;
  & h1 {
    text-align: center;
    margin-bottom: 1.5em;
  }
  & .user-deletion-form {
    width: 400px;
    margin: auto;
    .submit-errors {
      margin: 1.5em 0;
      & p {
        margin: 0;
        color: $cranberry;
        text-align: center;
        font-family: $headline;
      }
    }
    .submit-success {
      margin: 1.5em 0;
      & p {
        margin: 0;
        color: $teal;
        text-align: center;
        font-family: $headline;
      }
    }
    p.busy {
      text-align: center;
      margin: 0;
      font-family: $headline;
    }
    & .form-row {
      margin-bottom: 1em;
      
      & label {
        display: block;
        font-size: 12px;
        cursor: pointer;

        & svg {
          color: $cranberry;
          width: 8px;
          vertical-align: top;
          margin-left: .25em;
        }
      }
      &.error {
        & input {
          border-color: $cranberry;
        }  
      }
      & input {
        @include input;
      }
      & textarea {
        @include input;
        font-family: 'British Council Sans', sans-serif;
        resize: none;
      }
      & p.error {
        font-size: 14px;
        color: $cranberry;
        margin-top: .25em;
      }
      & button {
        width: 100%;
        margin-top: 2em;
      }
    }
  }
}
</style>