var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-deletion"}},[_c('h1',[_vm._v("Request User Deletion")]),_c('div',{staticClass:"user-deletion-form"},[_c('div',[_c('div',{staticClass:"submit-success"},[(_vm.message)?_c('p',[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]),_c('div',{staticClass:"submit-errors"},[(_vm.error)?_c('p',[_vm._v(" "+_vm._s(_vm.error.message)+" ")]):_vm._e()]),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('validation-provider',{attrs:{"rules":"required|uuid","name":"User ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-row",class:{ 'error': errors.length > 0 }},[_c('label',{attrs:{"for":"txtUserID"}},[_vm._v("User ID "),_c('fa',{attrs:{"icon":"asterisk"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.userID),expression:"formData.userID"}],attrs:{"id":"txtUserID","type":"text","disabled":_vm.isBusy,"placeholder":"Enter User ID","required":""},domProps:{"value":(_vm.formData.userID)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "userID", $event.target.value)}}}),(errors.length > 0)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email","name":"Email Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-row",class:{ 'error': errors.length > 0 }},[_c('label',{attrs:{"for":"txtEmailAddress"}},[_vm._v("Email Address"),_c('fa',{attrs:{"icon":"asterisk"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.emailAddress),expression:"formData.emailAddress"}],attrs:{"id":"txtEmailAddress","type":"text","disabled":_vm.isBusy,"placeholder":"Email Address","required":""},domProps:{"value":(_vm.formData.emailAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "emailAddress", $event.target.value)}}}),(errors.length > 0)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"min:3|max:250","name":"Reason"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-row",class:{ 'error': errors.length > 0 }},[_c('label',{attrs:{"for":"txtReason"}},[_vm._v("Reason")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.reason),expression:"formData.reason"}],attrs:{"rows":"5","id":"txtReason","type":"text","disabled":_vm.isBusy,"placeholder":"Enter Reason"},domProps:{"value":(_vm.formData.reason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "reason", $event.target.value)}}}),(errors.length > 0)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)}),(_vm.isBusy)?_c('p',{staticClass:"busy"},[_vm._v(" Please wait.. ")]):_vm._e(),_c('div',{staticClass:"form-row"},[_c('button',{staticClass:"es-btn teal",attrs:{"disabled":_vm.isBusy || invalid}},[_vm._v("Submit")])])],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }